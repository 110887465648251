<template>
  <div class="circleaudit">
    <div class="circleaudit_center">
      <!-- <h1>圈子审核</h1> -->
      <header>
        <p>教研圈审核</p>
      </header>
      <nav>
        <el-form ref="resourceRef" :model="resourceForm" :inline="true">
          <el-form-item label="教研圈名称" prop="circleName">
            <el-input v-model="resourceForm.circleName" @change="querylist" size="mini" clearable></el-input>
          </el-form-item>
          <el-form-item label="学科类型" prop="subjectTypeId">
            <el-select v-model="resourceForm.subjectTypeId" @change="changeshuject" size="mini" clearable>
              <el-option v-for="item in subjectypedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学科" prop="subjectId">
            <el-select v-model="resourceForm.subjectId" size="mini" clearable>
              <el-option v-for="item in subjectdata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学段" prop="periodId">
            <el-select v-model="resourceForm.periodId" @change="changeperiod" size="mini" clearable>
              <el-option v-for="item in perioddata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年级" prop="gradeId">
            <el-select v-model="resourceForm.gradeId" size="mini" clearable>
              <el-option v-for="item in gradedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="提交审核时间">
            <el-form-item prop="startTime" style="width:150px">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="resourceForm.startTime"
                style="width: 100%;"
                size="mini"
                clearable
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item prop="endTime" style="width:150px">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="resourceForm.endTime"
                style="width: 100%;"
                size="mini"
                clearable
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-form-item>
          <el-form-item label="审核状态" prop="auditStatus">
            <el-select v-model="resourceForm.auditStatus" placeholder="请选择" size="mini">
              <el-option v-for="item in auditdata" :key="item.id" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="nav_btns">
          <el-button type="primary" @click="querylist" size="mini">查询</el-button>
          <el-button @click="resetfrom('resourceRef')" size="mini">重置</el-button>
        </div>
      </nav>
      <aside>
        <el-button type="primary" @click="submitdata(null,1)" size="mini">批量审核通过</el-button>
        <el-button type="danger" @click="submitdata(null,0)" size="mini">批量审核不通过</el-button>
      </aside>
      <main>
        <el-table :data="resTabDatas" stripe style="width: 100%" @selection-change="handleSelectionChange" height="500">
          <el-table-column type="selection" width="50" align="center" fixed="left"></el-table-column>
          <el-table-column type="index" width="50" align="center" fixed="left" label="序号" :index="computeTableIndex"></el-table-column>
          <el-table-column prop="circleName" align="center" label="课程标题" width="300" fixed="left" show-overflow-tooltip></el-table-column>
          <el-table-column prop="periodName" label="学段" width="150" show-overflow-tooltip></el-table-column>

          <el-table-column prop="gradeName" label="年级" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="subjectTypeName" label="学科类型" width="150"></el-table-column>

          <el-table-column prop="subjectName" label="学科" width="150" show-overflow-tooltip></el-table-column>
          <!-- <el-table-column prop="resourceType" label="资源类型" width="120"></el-table-column> -->
          <el-table-column prop="createUser" label="创建者" width="150"></el-table-column>
          <el-table-column prop="auditStatus" label="审核状态" width="150"></el-table-column>
          <!-- <el-table-column prop="auditMessage" label="审核意见" width="300" show-overflow-tooltip></el-table-column> -->
          <el-table-column prop="createTime" label="创建时间" width="180"></el-table-column>
          <el-table-column prop="auditTime" label="提交审核时间" width="180"></el-table-column>
          <el-table-column fixed="right" label="操作" width="300">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="submitdata(scope.row.id,1)"
                :disabled="scope.row.auditStatus=='审核通过'||scope.row.auditStatus=='审核不通过'"
              >审核通过</el-button>
              <el-button type="text" size="mini" @click="viewdata(scope.row,0)" v-if="scope.row.auditStatus=='审核通过'">查看教研圈</el-button>
              <el-button type="text" size="mini" @click="viewdata(scope.row,1)" v-else>查看教研圈</el-button>
              <el-button type="text" size="mini" @click="record(scope.row)">审核记录</el-button>
              <el-button type="text" size="mini" @click="submitdata(scope.row.id,0)" style="color: #EB5757;">审核不通过</el-button>
              <!-- <el-button size="mini" type="success" @click="ifagree(scope.row.id,1)" :disabled="scope.row.auditStatus=='审核通过'">通过</el-button>
              <el-button size="mini" type="danger" @click="ifagree(scope.row.id,0)">驳回</el-button>
              <el-button size="mini" type="warning" @click="checkClass(scope.row.auditMessage)">查看</el-button>
              <el-button size="mini" type="info" @click="record(scope.row)">记录</el-button>-->
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="curpage"
          :page-sizes="[10, 20, 30, 40 ,50]"
          :page-size="pageSize"
          layout="total,sizes, prev, pager, next"
          :total="total"
        ></el-pagination>
      </main>

      <!--  -->
      <el-dialog title="审核记录" :visible.sync="checkeRcordsDialog" top="5%" class="checkTab">
        <el-table :data="checkRecordsData">
          <el-table-column property="date" label="审核日期" width="200"></el-table-column>
          <el-table-column property="message" label="审核信息"></el-table-column>
          <el-table-column property="userName" label="审核人" width="200"></el-table-column>
        </el-table>
      </el-dialog>
      <el-dialog
        title="审核不通过"
        :visible.sync="disagreeDialog"
        top="5%"
        class="checkTabs"
        :closeOnClickModal="false"
        :showClose="false"
        :closeOnPressEscape="false"
      >
        <el-input type="textarea" v-model="disagreeText" :rows="5"></el-input>
        <div class="butognguo">
          <el-button type="danger" @click="sureDisagree" size="mini">审核不通过</el-button>
          <el-button @click="cancelDisagree" size="mini">取消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 课程审核列表
      resourceForm: {
        circleName: null,
        // resourceType: null,
        subjectTypeId: null,
        subjectId: null,
        periodId: null,
        gradeId: null,
        startTime: null,
        endTime: null,
        auditStatus: null,
      },
      // 资源类型
      // restypedata: null,
      //学科类型
      subjectypedata: null,
      // 学科
      subjectdata: null,
      // 学段
      perioddata: null,
      // 年级
      gradedata: null,
      //审核状态
      auditdata: null,
      // 表格数据
      resTabData: null,
      // 多选的数据
      multipleSelection: null,

      // 分页
      curpage: 1, //当前页数
      pageSize: 10, //当前条数
      total: null, //总数

      // 审核记录弹框
      checkeRcordsDialog: false,
      checkRecordsData: null,
      //审核不通过弹框
      disagreeDialog: false,
      // 审核不通过的原因
      disagreeText: null,
      disagreeId: null,
    };
  },
  created() {
    // this.queryResourceType(); // 获取资源类型
    this.querySubjectType(); //获取课程类型
    this.querySubject(0); //获取学科信息
    this.queryPeriod(); //获取学段
    this.queryGrade(0); //获取年级信息
    this.querystatus(); //获取审核状态
    // 获取课程列表
    this.queryCircles();
  },
  methods: {
    // 查询课程列表
    async queryCircles() {
      // console.log(this.resourceForm, "resourceForm");
      const { data, status } = await this.$Http.queryCircles({
        // circleName : circleName, /课程名称
        // subjectTypeId : subjectTypeId, //课程类型ID
        // subjectId : subjectId, //学科ID
        // periodId : periodId, //学段ID
        // gradeId : gradeId, //年级ID
        // startTime : startTime, //审核开始时间
        // endTime :endTime,//审核结束时间
        // auditStatus : auditStatus, //审核状态
        ...this.resourceForm,
        // courseType: 1, //课程类型(1-课程，2-资料)
        // courseIdList: null,
        // sortId: 1, //排序Id
        // sortType: 1, //排序类型
        pageNo: this.curpage, //页码
        pageSize: this.pageSize, //每页数量
      });
      // const { data, status } = await this.$Http.queryAuditCourse({
      //   ...this.classFrom,
      //   // auditStatus: auditStatus, //审核状态
      //   // endTime: endTime, //审核结束时间
      //   // startTime: startTime, //审核开始时间
      //   // circleName: circleName, //课程名称
      //   // gradeId: gradeId, //年级ID
      //   pageNo: this.currentPage, //页码
      //   pageSize: 10, //每页数量
      //   // periodId: periodId, //学段ID
      //   // subjectId: subjectId, //学科ID
      //   // subjectTypeId: subjectTypeId, //课程类型ID
      // });
      if (status == 200) {
        // console.log(data, status, "审核表格");
        this.resTabData = data;
        // this.resetfrom('resourceRef');
      }
    },
    // 获取资源类型
    // async queryResourceType() {
    //   await this.$Http.queryResourceType().then((res) => {
    //     console.log(res, "获取资源类型");
    //     if (res.status == 200) {
    //       this.restypedata = res.data;
    //     }
    //   });
    // },
    // 获取课程类型
    async querySubjectType() {
      await this.$Http.querySubjectType().then((res) => {
        // console.log(res, "获取课程类型");
        if (res.status == 200) {
          this.subjectypedata = res.data;
        }
      });
    },
    // 获取学科信息
    async querySubject(id) {
      // console.log(id);
      let ids = [];
      if (id !== 0) {
        ids.push(id);
      }
      const { data, status } = await this.$Http.querySubject({
        idList: ids,
      });
      if (status == 200) {
        // console.log(data, status, "获取学科");
        this.subjectdata = data;
      }
    },
    // 获取学段
    async queryPeriod() {
      await this.$Http.queryPeriod().then((res) => {
        // console.log(res, "获取学段");
        if (res.status == 200) {
          this.perioddata = res.data;
        }
      });
    },
    // 获取年级信息
    async queryGrade(id) {
      // console.log(id);
      let ids = [];
      if (id !== 0) {
        ids.push(id);
      }
      const { data, status } = await this.$Http.queryGrade({
        idList: ids,
      });
      if (status == 200) {
        // console.log(data, status, "获取年级信息");
        this.gradedata = data;
      }
    },
    //审核状态
    async querystatus() {
      // console.log("获取审核状态");
      let aa = [
        {
          id: 0,
          name: "未提交审核",
          value: "0",
        },
        {
          id: 1,
          name: "审核不通过",
          value: "1",
        },
        {
          id: 2,
          name: "提交待审核",
          value: "2",
        },
        {
          id: 3,
          name: "审核通过",
          value: "3",
        },
      ];
      this.auditdata = aa;
    },
    // 学科类型的值得变化
    changeshuject(val) {
      this.resourceForm.subjectId = null;
      if (!val) {
        this.querySubject(0);
      } else {
        this.querySubject(val);
      }
    },
    // 学段的值得变化
    changeperiod(val) {
      this.resourceForm.gradeId = null;
      if (!val) {
        this.queryGrade(0);
      } else {
        this.queryGrade(val);
      }
    },

    // 查询
    querylist() {
      this.queryCircles();
    },
    // 重置
    resetfrom(formName) {
      // console.log(this.$refs, "重置");
      this.$refs[formName].resetFields();
      this.querySubject(0); //获取学科信息
      this.queryGrade(0); //获取年级信息
      // this.queryCircles();
    },

    // ------

    // 提交
    async submitdata(rowId, type) {
      // let ids = [];
      // if (rowId == null) {
      //   if (this.multipleSelection == null) {
      //     this.$message.warning("请选择要批量提交的课程");
      //     return;
      //   } else {
      //     for (let x = 0; x < this.multipleSelection.length; x++) {
      //       ids.push(this.multipleSelection[x].id);
      //     }
      //   }
      // } else {
      //   ids.push(rowId);
      // }
      // const { data, status } = await this.$Http.updateAudit({
      //   courseId: ids,
      // });
      // if (status == 200) {
      //   console.log(data, status, "课程提交审核");
      //   this.queryCircles();
      // }
      // console.log(rowId, type, "是否通过");
      if (type == 1) {
        // console.log("审核通过1");
        let idList = [];
        if (rowId == null) {
          if (!this.multipleSelection) {
            this.$message.warning("请选择要批量通过的教研圈");
            return;
          } else {
            for (let a = 0; a < this.multipleSelection.length; a++) {
              idList.push(this.multipleSelection[a].id);
            }
          }
        } else {
          idList.push(rowId);
        }
        this.doagree(idList, 1);
      } else if (type == 0) {
        let idList = [];
        if (rowId == null) {
          for (let a = 0; a < this.multipleSelection.length; a++) {
            idList.push(this.multipleSelection[a].id);
          }
        } else {
          idList.push(rowId);
        }
        this.disagreeId = idList;
        this.disagreeDialog = true;
      }
    },
    // 取消不通过
    cancelDisagree() {
      this.disagreeDialog = false;
      this.disagreeText = null;
    },
    // 确定审核不通过
    sureDisagree() {
      this.$confirm("确定审核不通过?", "审核不通过", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.doagree(this.disagreeId, 0);
      });
    },
    //
    async doagree(rowId, type) {
      // console.log(rowId, "审核通过2", type);
      if (type) {
        const { data, status } = await this.$Http.updateCircleApproved({
          // auditType: type, //审核类型 0-审核不通过 1-审核通过
          circleIdList: rowId, //课程id集合
          message: "通过", //审核意见
        });
        if (status == 200) {
          // console.log(data, status, "审核通过31");
          this.$message({
            type: "success",
            message: "审核通过",
          });
          this.disagreeId = null;
          this.disagreeText = null;
          this.disagreeDialog = false;
          // this.$emit("tabdata");
          this.queryCircles();
        }
      } else {
        const { data, status } = await this.$Http.updateCircleNoApproved({
          // auditType: type, //审核类型 0-审核不通过 1-审核通过
          circleIdList: rowId, //课程id集合
          message: this.disagreeText, //审核意见
        });
        if (status == 200) {
          // console.log(data, status, "审核通过32");
          this.$message({
            type: "success",
            message: "审核不通过",
          });
          this.disagreeId = null;
          this.disagreeText = null;
          this.disagreeDialog = false;
          // this.$emit("tabdata");
          this.queryCircles();
        }
      }
    },

    // 查看
    viewdata(row,num) {
      console.log(row);
      this.$router.push({
        path: "/circleintroduce",
        query: {
          introId: row.id,
          circlesite: num,
        },
      });
    },
    // 审核记录
    async record(row) {
      // console.log(row, "审核记录");
      this.checkRecordsData = row.auditDetail;
      this.checkeRcordsDialog = true;
    },
    // 编辑
    editdata(row) {
      // console.log(row);
      this.$router.push({
        path: "/upclass",
        query: { id: row.id },
      });
    },
    // 多选
    handleSelectionChange(val) {
      // console.log(val, "xuzhogn");
      this.multipleSelection = val;
    },
    //监听index
    computeTableIndex(index) {
      // console.log(index, this.queryInfo.pagenum, this.queryInfo.pagesize, 'e')
      return (this.curpage - 1) * this.pageSize + index + 1;
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.queryCircles();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.curpage = val;
      this.queryCircles();
    },
  },
  computed: {
    resTabDatas() {
      // console.log(this.resTabData);
      let a = this.resTabData;
      this.total = 0;
      if (a !== null) {
        let aa = this.resTabData.list;
        this.total = this.resTabData.total;
        if (aa[0].periodName.join === undefined) {
          return aa;
        }
        for (let x = 0; x < aa.length; x++) {
          aa[x].periodName = aa[x].periodName.join("/");
          aa[x].gradeName = aa[x].gradeName.join("/");
          //   aa[x].subjectName = aa[x].subjectName.join("/");
          //   aa[x].subjectType = aa[x].subjectType.join("/");
        }
        return aa;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.circleaudit {
  .circleaudit_center {
    header {
      height: 70px;
      padding: 0 20px;
      background-color: #fff;
      border-bottom: 1px solid #ebebeb;
      p {
        // width: 108px;
        // height: 24px;
        height: 70px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 600;
        color: #141516;
        line-height: 70px;
      }
    }
    nav {
      background-color: #fff;
      padding: 15px;
      // .el-form-item{
      //   width: 230px;
      // }
      .el-input {
        width: 150px;
      }
      .el-select {
        width: 120px;
      }
      .nav_btns {
        display: flex;
        justify-content: flex-end;
      }
    }
    aside {
      margin-top: 10px;
      background-color: #fff;
      padding: 20px;
    }
    main {
      padding: 0 20px;
      background-color: #fff;
      .el-pagination {
        display: flex;
        justify-content: center;
        padding: 20px 0;
      }
    }
    .checkTabs {
      .butognguo {
        margin-top: 23px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
</style>